import React from "react";
import { graphql, navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    "& .MuiChip-root": {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      height: "48px",
      "& .MuiChip-label": {
        fontSize: "120%",
        lineHeight: "48px",
      },
    },
  },
}));

function Tags({ data }) {
  const classes = useStyles();
  function handleTagClick(e) {
    e.preventDefault();
    let to = e.target.parentElement.getAttribute("href");
    navigate(to);
  }
  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {data.allTaxonomyTermTags &&
          data.allTaxonomyTermTags.edges &&
          data.allTaxonomyTermTags.edges.map(({ node }) => (
            <Chip
              key={node.drupal_id}
              label={node.name}
              icon={<LocalOfferIcon />}
              component="a"
              href={node.fields.slug}
              onClick={(e) => handleTagClick(e)}
            />
          ))}
      </Container>
    </>
  );
}

export default Tags;

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "tags" } } }) {
      nodes {
        frontmatter {
          title
        }
      }
    }
    allTaxonomyTermTags(sort: { order: ASC, fields: name }) {
      edges {
        node {
          drupal_id
          name
          fields {
            slug
          }
        }
      }
    }
  }
`;
